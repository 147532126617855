<template>
  <div class="Home">
    <page-header/>
<!--    <section class="uk-section uk-padding-small">-->
<!--      <div class="uk-container">-->
<!--        <div class="uk-width-1-1 uk-margin-remove-left" uk-grid>-->
<!--          <div class="uk-width-1-5 uk-padding-remove">-->
<!--            <input type="text" class="uk-input" placeholder="Exam name" v-model="exam.examName">-->
<!--          </div>-->
<!--          <div class="uk-width-1-5">-->
<!--            <select class="uk-select" v-model="exam.filter">-->
<!--              <option>Tomorrow</option>-->
<!--              <option>Today's</option>-->
<!--            </select>-->
<!--          </div>-->
<!--          <div class="uk-width-1-5">-->
<!--            <date-picker-->
<!--              v-model="exam.endDate"-->
<!--              type="date"-->
<!--              class="datepicker"-->
<!--              lang-->
<!--              placeholder="Exam end date"-->
<!--            ></date-picker>-->
<!--          </div>-->
<!--          <div class="uk-width-1-5">-->
<!--            <div class="uk-grid-small uk-child-width-auto uk-grid uk-margin-small-left">-->
<!--              <label style="color: #0e6dcd">-->
<!--                <input class="uk-checkbox" type="checkbox" v-model="exam.gived"-->
<!--                       style="margin-right: 10px;"> Gived</label>-->
<!--              <label style="color: #0e6dcd">-->
<!--                <input class="uk-checkbox" type="checkbox" v-model="exam.realexam"-->
<!--                       style="margin-right: 12px;">Real exam</label>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="uk-width-1-5">-->
<!--            <button type="button" class="uk-button uk-button-primary uk-button-small" @click="window.location.reload()">-->
<!--              {{$t("orgaExam.examResultView.default")}}-->
<!--            </button>-->
<!--            <button type="button" class="uk-button uk-button-primary uk-button-small" @click="searchFilter">-->
<!--              {{$t("orgaExam.examResultView.search")}}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <section class="uk-section uk-padding-remove">
      <div class="uk-container">
        <div class="uk-card uk-card-default uk-box-shadow-medium">
          <table class="uk-table uk-table-divider uk-table-responsive uk-table-small">
            <thead>
            <tr>
              <th class="uk-text-center">No</th>
              <th class="uk-text-center">{{$t("orgaExam.examResultView.endDate")}}</th>
              <th class="uk-text-center">{{$t("orgaExam.examResultView.eName")}}</th>

              <th class="uk-text-center">{{$t("orgaExam.examResultView.variant")}}</th>
              <th class="uk-text-center">{{$t("orgaExam.examResultView.totalQues")}}</th>
              <th class="uk-text-center">{{$t("orgaExam.examResultView.aveScore")}}</th>
<!--              <th class="uk-text-center">{{$t("orgaExam.examResultView.aveTime")}}</th>-->
              <th class="uk-text-center">
                {{$t("orgaExam.examResultView.analysis")}}
              </th>
              <th class="uk-text-center">
                {{$t("orgaExam.examResultView.cleanResult")}}
              </th>
            </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in exams">
                <tr class="uk-text-center" :key="index">
                  <td  v-bind:rowspan="item.varinfos.length + 1"
                      style="border-bottom-left-radius: 15px">{{index+1}}
                  </td>
                  <td v-bind:rowspan="item.varinfos.length + 1">{{item.end_date|moment}}</td>
                  <td v-bind:rowspan="item.varinfos.length + 1">{{item.exam_name}}</td>
                </tr>
                <tr class="uk-text-center" v-for="data in item.varinfos" :key="data.id">
                  <td>
                    <span>{{data.variant}}</span>
                  </td>
                  <td>
                    <a href="#" v-if="data.qcount > 0" @click="takeExamUrl('exam_check',item.id, data.variant)">
                      {{data.qcount}}
                    </a>
                    <span v-else>
                      0
                    </span>
                  </td>
                  <td >
                    <span>
                      {{data.averageScore.toFixed(2)}}
                    </span>
                  </td>
<!--                  <td >-->
<!--                    <span>-->
<!--                      {{data.averageTime}}-->
<!--                    </span>-->
<!--                  </td>-->
                  <td>
                    <a href="#" @click="takeExamUrl('exam_result_see',item.id, data.variant)">
                      {{data.givedcount}}/{{data.mustcount}}
                    </a>
                  </td>
                  <td>
                    <button type="button" class="uk-button uk-button-danger uk-button-small uk-text-capitalize"
                            @click="examCleanModal(item.id)"
                            v-if="currentUser.id == item.reg_id || currentUser.authorities[0].authority == 'ROLE_ADMIN'">
                      {{$t("orgaExam.examResultView.clean")}}
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <div id="cleanExam" class="uk-flex-top" uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style="border-radius: 15px;">
        <button class="uk-modal-close-default"  uk-close></button>
        <span class="uk-flex uk-flex-center" uk-icon="icon:info; ratio:3.5"></span>
        <h5 class="uk-text-center" style="margin-bottom: 0px; font-size: 22px; padding-bottom: 25px;">
          {{$t("dcleanExam")}}
        </h5>
        <div class="uk-modal-footer uk-padding-remove-bottom">
          <div class="uk-flex uk-flex-center">
            <button type="button" class="uk-button uk-button-danger uk-margin-right"
                    @click="examCleanAll()">Yes</button>
            <button type="button" class="uk-button uk-button-default uk-modal-close">No</button>
          </div>
        </div>
      </div>
    </div>

    <section class="uk-section uk-padding-remove ">
      <div class="uk-container">
        <div class="uk-card-footer uk-text-center">
          <div class="uk-flex uk-flex-center">
            <v-pagination
              v-model="currentPage"
              :page-count="pages"
              :classes="uikitClasses"
              :labels="Labels"
              @change="onChange"
            ></v-pagination>
          </div>
        </div>
      </div>
    </section>

    <page-footer/>
  </div>

</template>
<script>

  import {mapGetters} from "vuex";
  import vPagination from "@/components/globals/vue-plain-pagination"
  import ExamService from '@/services/ExamService'
  import moment from "moment";
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'

  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");
  import UIkit from "uikit";

  export default {
    name: "app",
    components: {
      vPagination,
      PageHeader,
      PageFooter

    },
    computed: {
      ...mapGetters(["isLoggedIn","getToken", "currentUser"])
    },
    data() {
      return {
        agreeType: "",
        description: "",
        payment: "",
        examDate: "",
        examDateEnd: "",
        currentPage: 1,
        pages: 0,
        examDetail: null,
        exam: {
          examName: '',
          filter: '',
          option: '',
          realexam: '',
          gived: '',
          endDate: ''
        },
        exams: [],
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
        },
        placeholder: {
          date: "Select Date",
          date2: "End date",
          dateRange: "End Date"
        }, shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        },
        uikitClasses: {
          ul: "uk-pagination",
          li: "",
          liActive: "uk-active",
          liDisable: "uk-disabled",
          button: "page-link"
        },
        Labels: {
          first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
          prev: "<span uk-pagination-previous></span>",
          next: "<span uk-pagination-next></span>",
          last: "Last"
        },
        cleanExamId: '',
        // localPath: 'http://localhost:8084/exam/teacher',
        localPath: '/ubtexam/teacher',
      };
    },
    filters: {
      moment: function (date) {
        return moment(date).format('YYYY/MM/DD HH:mm')
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      examCleanModal(examId){
        this.cleanExamId = examId;
        UIkit.modal("#cleanExam").show();
      },
      async examCleanAll(){
        try {
          UIkit.modal("#cleanExam").show();
          const response = await ExamService.examClean({examId: this.cleanExamId});
          if (response.data.status == 200) {
            if (response.data.success == true)
            {
              alert(response.data.message);
              UIkit.modal("#cleanExam").hide();
              this.loaddata(this.currentPage)
            }
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          console.log(error);
        }
      },
      takeExamUrl(link, examId, variant) {
        this.$setCookie('utoken', this.getToken);
        this.$setCookie('urole', this.currentUser.authorities[0].authority);
        this.$setCookie('uorganizId', this.currentUser.organizs[0].organizId);
        let lang = this.$i18n.locale== "kr" ? "ko" : this.$i18n.locale;

        window.open(this.localPath + "/" + link + "?lang="+  lang + "&examId=" + examId + "&variant=" + variant, '_blank',
          'width=1440,height=880')
      },
      searchFilter() {
        this.loaddata(this.currentPage);
      },
      searchDefault() {
        window.location.reload();
      },
      async loaddata(page) {
        try {
          if (page > 0) {
            page = page - 1;
          }
          let item = {
            page: page,
            examName: this.exam.examName,
          };
          const response = await ExamService.viewExamResults(item);

          if (response.status == 200) {
            for (let i=0; i < response.data.content.length; i++)
            {
              var resItem = response.data.content[i];

              for(let j = 0; j < resItem.varinfos.length; j++) {
                response.data.content[i].varinfos[j].averageScore = 0;
                response.data.content[i].varinfos[j].averageTime = 0;

                var varItem = resItem.varinfos[j];

                if (varItem.givedcount > 0) {
                  varItem.averageScore = varItem.givedtotalscore / varItem.givedcount;
                  varItem.averageTime = varItem.givedtotaltime / varItem.givedcount
                }

                if (varItem.averageTime != 0) {
                  varItem.averageTime = parseInt( varItem.averageTime);
                  let ahours = Math.floor( varItem.averageTime / 3600);
                  varItem.averageTime %= 3600;
                  let aminutes = Math.floor( varItem.averageTime / 60);
                  let aseconds =  varItem.averageTime % 60;
                  varItem.averageTime = ahours + ":" + aminutes + ":" + aseconds
                }
              }
            }

            this.exams = response.data.content;

            // this.currentPage = response.data.page;
            this.pages = response.data.totalPages;

            if (!this.exams.length) {
              this.isLoading = false;

            }
          }
        } catch (error) {
          console.log(error.response);
          this.error = error.response.data.message;
          this.scrollToTop();
        }
      },
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      onChange: function () {
        this.loaddata(this.currentPage);
      },
    }
  };
</script>
<style scoped>

  .uk-table td.subtit {
    border-right: 1px solid #dee2fe;
    text-align: center;
    font-weight: bold;
    color: #404348;
    font-size: 14px;
    background-color: #eeeeef;
  }

  .uk-table td.subtits {
    border-right: 1px solid #dee2fe;
    border-left: 1px solid #dee2fe;
    font-weight: bold;
    padding: 20px 0px;
    padding-left: 10px;
    color: #404348;
    font-size: 14px;
    background-color: #eeeeef;
  }

  .uk-table td {
    border-bottom: 1px solid #dee2fe;
    border-top: 1px solid #dee2fe;
    font-size: 13px;
    height: 35px;
  }

  thead{
    border-radius: 15px;
  }
  tbody{
    border-radius: 15px;
  }
  .q-btn{
    text-transform: capitalize;
    border-radius: 15px;
    color: #fff;
    background: #0d9df9;
  }
  .uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    height: 42px;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
    /*background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A),linear-gradient(#01b0ff, #38b8f2);*/
    background-repeat: no-repeat;
    background-position: 80% 50%;
    border-radius: 15px;
    text-align: center;
    color: #0d9df9;
  }
  .datepicker >>> input {
    height: 46px !important;
    border-radius: 15px !important;
    text-align: center;
    color: #0d9df9;
    border: none;
  }

  .datepicker >>> input::placeholder {
    color: #0d9df9;
  }
  .datepicker >>> .mx-input-append {
    margin-right: 18px;
  }
  .uk-input{
    border-radius: 15px;
    height: 42px;
    text-align: center;
    color: #0d9df9;
  }
  .uk-input::placeholder{
    color: #0d9df9;
  }
  .uk-table-small th, .uk-table-small td {
    padding: 19px 12px;
    font-size: 15px;
    vertical-align: middle;
  }
</style>
